import React from 'react'
import './Home.css'
import { InlineWidget } from 'react-calendly'
import './Calendly.css'


function Calendly() {

    return (
        <div>
            <div className="container-fluid cal-div1">
                <h1 className='book-text'>Book A <br></br>Meeting</h1>
                <div className='book-div2'>
                    <InlineWidget url="https://calendly.com/brainsoulscro/30min?primary_color=40e570" styles={{ height: '700px' }}></InlineWidget>
                </div>
            </div>
        </div>

    )
}

export default Calendly
