import logo from './logo.svg';
import './App.css';
import Home from './Components/Home'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Calendly from './Components/Calendly'
import ScrollToTop from './Components/ScrollToTop'

import { BrowserRouter, Route, Routes } from "react-router-dom";



function App() {
  return (
    <div>

     <BrowserRouter>
        <ScrollToTop></ScrollToTop>

        <Navbar></Navbar>
        <Routes>

          <Route path="/" element={<Home></Home>} />
          <Route path="/bookameeting" element={<Calendly></Calendly>} />

        
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
