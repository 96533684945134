import React from "react";
import "./Footer.css";


function Footer() {
  return (
    <div>
      <div className="container-fluid footer-div1">
        <div className="footer-div2">
          <div className="footer-div3">
            <div className="container">
              <div>
                {/* <p className="footer-text1">Have a Cool Project Idea?</p> */}
                <div className="footer-textdivs12">
                  <h1 className="footer-text2">
                    Try Free <br></br>
AUDIIIT
                  </h1>
                  <div>
                      <a href='https://calendly.com/iamqasim/30min' target='_blank'>
                    <div className="footer-svg1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="162"
                        height="164"
                        viewBox="0 0 162 164"
                        fill="none"
                      >
                        <path
                          d="M150.322 42.552L149.349 41.1925L149.053 41.2609C148.855 41.3044 129.078 45.6334 111.265 35.0216L111.004 34.8647L109.946 36.6372L110.206 36.7942C115.716 40.0773 121.939 42.3072 128.698 43.4224C135.35 44.5205 141.18 44.3343 145.207 43.9156L29.4394 126.813L29.1919 126.991L30.3951 128.67L146.411 45.5945C143.585 51.7515 138.604 65.5596 141.899 80.9986L141.962 81.295L143.984 80.8645L143.922 80.5681C139.58 60.2233 150.058 42.9834 150.164 42.809L150.325 42.5515L150.322 42.552Z"
                          fill="url(#paint0_linear_2769_3690)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2769_3690"
                            x1="137.339"
                            y1="30.4026"
                            x2="29.2927"
                            y2="128.433"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop />
                            <stop offset="1" stop-color="#2BC264" />
                          </linearGradient>
                        </defs>
                      </svg>
                      
                    </div>
                    </a>
                  </div>
                </div>

               

                <div className="footer-svgline2"></div>

                <div>
                  <p className="copyright-text1">
                    © Audiiit - E-commerce CRO Design & Development Agency
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
